body {
    margin-bottom: 0 !important;
}

img {
    max-width: 100%;
}

p {
    margin-top: 0;
    margin-bottom: 16px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

/*.slick-slide {*/
/*  margin: 0 12px;*/
/*}*/
/*!* the parent *!*/
.slick-list {
    margin: 0 -12px;
}

.lineBox {
    position: relative;
    margin: 0 auto 10px auto;
    width: 145px;
    height: 1px;
    background: #1ABCFE;
}

.lineBox:before,
.lineBox:after {
    content: '';
    position: absolute;
    display: block;
    bottom: -8px;
    width: 1px;
    height: 8px;
    background: #1ABCFE;
}

.lineBox:before {
    left: 0;
}

.lineBox:after {
    right: 0;
}

.slick-next,
.slick-prev {
    width: 40px;
    height: 40px;
    z-index: 9999;
}

.slick-next {
    right: -20px;
}

.slick-prev {
    left: -20px;
}

@media (min-width: 1600px) {
    .slick-next {
        right: -60px;
    }

    .slick-prev {
        left: -60px;
    }
}

.slick-prev:before,
.slick-next:before {
    content: '';
    display: block;
    width: 40px;
    height: 40px;
    background: url("./assets/prev.svg") no-repeat center center;
    background-size: contain;
}

.slick-prev:before {
    background: url("./assets/prev.svg") no-repeat center center;
}

.slick-next:before {
    background: url("./assets/next.svg") no-repeat center center;
}
